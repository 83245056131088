/* src/ChatWidget.css */
.chat-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-widget {
    width: 100%;
    max-width: 350px;
    margin-bottom: 16px;
    border-radius: 8px;
}

.message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    word-break: break-word;
}

.message.user {
    margin-left: auto;
    /* text-align: right; */
}

.message.support {
    margin-right: auto;
    text-align: left;
}

/* Custom scrollbar styles */
.chat-body {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.chat-body::-webkit-scrollbar {
    width: 8px;
}

.chat-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Custom scrollbar styles for Firefox */
.chat-body::-moz-scrollbar {
    width: 8px;
}

.chat-body::-moz-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.chat-body::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.chat-body::-moz-scrollbar-thumb:hover {
    background: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
    .chat-widget {
        width: 100%;
        max-width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        margin: 0;
        border-radius: 0;
    }

    .chat-header {
        padding: 16px;
    }

    .chat-body {
        height: calc(100% - 112px);
    }

    .chat-input {
        padding: 16px;
    }
}


.chat-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    /* Make it fixed to be sticky */
    /* bottom: 16px !important; */
    bottom: 26px !important;
    right: 16px;
    z-index: 1000;
    /* Ensure it appears above other elements */
}

@media (max-width: 768px) {

    .chat-container {

        bottom: 12px !important;
        /* right: 16px !important; */
    }
    .chat-btn{
        
        right: 16px !important;

    }


}