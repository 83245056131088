* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--selection-color: #ffa80030;
}

::selection {
	background: var(--selection-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.str-video {
	background-color: #272a30;
	color: #ffffff;
	height: 100dvh;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-width: 0;
	max-width: 100%;
  }